import React from 'react';
import './compare.component.css';

function Compare() {
    return (
        <div className="compare">
            <table>
                <tr>
                    <th>Parameters</th>
                    <th style={{width:'200px'}}>tag8</th>
                    <th style={{width:'200px'}}>Traditional</th>
                </tr>
                <tr>
                    <td>Lifetime Lost & Found Service</td>
                    <td><i className="fa fa-check-circle"></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Worldwide Cover</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Finder Reward Program </td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>NFC Features</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>Access to contact information</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                </tr>
                <tr>
                    <td>Community Search</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-times-circle'></i></td>
                </tr>
                <tr>
                    <td>{`Cost < $5`}</td>
                    <td><i className='fa fa-check-circle'></i></td>
                    <td><i className='fa fa-check-circle'></i></td>
                </tr>
            </table>
        </div >
    )
}

export default Compare