import React, { useEffect, useState } from "react";
import "./footer.component.css";

function Footer() {
  const [urls, seturls] = useState({
    privacyPolicyUrl: "#",
    termAndConditionUrl: "#",
  });
  const getData = async () => {
    try {
      const ipApiResponse = await fetch("https://ipapi.co/json/");
      const countryData = await ipApiResponse.json();
      const ipAddress = countryData.country;

      seturls({
        privacyPolicyUrl:
          ipAddress === "IN"
            ? "https://home.tag8.in/policies/privacy-policy"
            : "https://home.tag8.co/policies/privacy-policy",
        termAndConditionUrl:
          ipAddress === "IN"
            ? "https://home.tag8.in/policies/terms-of-service"
            : "https://home.tag8.co/policies/terms-of-service",
      });
    } catch (error) {
      console.error("Error fetching IP data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="footer">
        <div className="left-content">
          © {new Date().getFullYear()} Powered by 3Y Ventures LLP All Rights
          Reserved |{" "}
          <a href={urls.termAndConditionUrl} style={{ color: "white" }}>
            Legal Terms & Conditions
          </a>{" "}
          |{" "}
          <a href={urls.privacyPolicyUrl} style={{ color: "white" }}>
            Privacy Policy
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
